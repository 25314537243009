import {showNotification} from "@/utils/aux_functions";
import {aiseoBackendPetition} from "@/utils/aiseo_petition";
import store from "@/store";

export async function getAllLanguagesWS(form_filter) {

    let params_token = {
        'usuario_logueado': store.getters.getUsername,
    }
    let params = {
        'id_idioma' : form_filter.id_language ?? null,
        'activo' : form_filter.active ?? null,
        'nombre_idioma' : form_filter.name ?? null,
        'id_language_base' : form_filter.id_language_base ?? null,
        'iso_2' : form_filter.iso_2 ?? null,
        'iso_6391' : form_filter.iso_6391 ?? null,
        'exact_search' : form_filter.exact_search ?? 0,
    }

    try {
        return await aiseoBackendPetition('POST', 'language/getLanguages', params, params_token)
    } catch (e) {
        showNotification('error', process.env.VUE_APP_ERROR_SERVER + 'language/getLanguages');
    }
}