import store from "@/store";
import {aicoreBackendPetition} from "@/utils/aicore_petition";
import {aiseoBackendPetition} from "@/utils/aiseo_petition";
import {showNotification, variableIsDefined} from "@/utils/aux_functions";


export async function getCoursesRelatedWS(id_persona){
    let url = "/LeadScore/extrae_cursos_relacionados?id_persona=" + id_persona

    return await aicoreBackendPetition('GET', url, {})
}

export async function getURLByPortalWS(tipo_url = 'blogs', page = 1, rows_per_page = 20, filters, order_direction = "", order_by = "", id_portal = 540, iso = 'es') {
    let params_token = {
        'portal': id_portal,
        'mode': tipo_url,
        'page_size': rows_per_page,
        'languageISO': iso,
    };

    let params = {
        ...params_token,
        'url_search': variableIsDefined(filters.url_search) ? filters.url_search : "",
        'url_search_type': variableIsDefined(filters.url_search_type) && variableIsDefined(filters.url_search) ? filters.url_search_type : "",
        'id_simo': variableIsDefined(filters.id_simo) ? filters.id_simo : "",
        'codigo_innotutor': variableIsDefined(filters.id_innotutor) ? filters.id_innotutor : "",
        'count_claustro': tipo_url.toLowerCase() !== 'blogs',
        'order_direction': order_direction,
        'order_by': order_by,
        'date_from': variableIsDefined(filters.filter_date) ? filters.filter_date : "",
        'actives': filters.state_urls,
        'renderables': filters.renderables,
        'category':  variableIsDefined(filters.category) ? filters.category : "",
    }

    if (variableIsDefined(filters.check_get_draft) && filters.check_get_draft) {
        params.hasPendingDraft = 1
    }

    try {
        return await aiseoBackendPetition('POST', 'getPortalURLs?page=' + page, params, params_token)
    } catch (e) {
        showNotification('error', process.env.VUE_APP_ERROR_SERVER + ' getPortalURLs');
    }
}

