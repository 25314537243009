<template>
  <v-container>
    <v-row align="center" justify="center">
      <v-col cols="12" sm="8" md="8" lg="9">
        <h1 class="mb-5 title">Prototipo de detección de tendencias</h1>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <p>
          Esta es una primera aproximación a la detección de tendencias. En esta versión la búsqueda es complementamente léxica y nos permite obtener con cierta confianza los cursos más relacionados a un conjunto de palabras claves relevantes.
        </p>
      </v-col>
    </v-row>
    <v-row align="center" cols="12">
      <div class="input-group mb-3" style="justify-content:center;">
        <span class="input-group-text">Seleccionar Rol</span>
        <select v-model="selectedRol" class='btn button-primary w-50' id="rolesID">
          <option disabled value="">Seleccione un rol</option>
          <option class="text-dark" v-for="rol in idRoles" :key="rol">{{ rol['nombre'] }}</option>
        </select>
      </div>
    </v-row>
  </v-container>
  <v-row v-if="!initialRequest" >
    <Loading/>
  </v-row>
  <v-container v-else>
      <v-form  @submit.prevent="null">
        <v-row cols="12" md="12">
          <v-data-table
            v-model="selectedTrend"
            :headers="trendsColumns"
            :items="trendsData"
            item-value="name"
            items-per-page="10"
            class="elevation-1"
            select-strategy="single"
            return-object
            show-select>
			  <template v-slot:item.data="{ value }">
				  {{ getFormattedValues(value)  }}
			  </template>
		</v-data-table>
        </v-row>
        <v-row cols="12">
          <div class="input-group">
            <v-col cols="12" md="4">
              <v-text-field
                type="text"
                v-model="portalesIncluidos"
                label="Lista de portales que incluir separados por coma."
                required
                variant="underlined"
                hide-details
                disabled
                :error="error_name"
                class="input-error"
                :class="{error :error_name}"
              />
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field
                type="text"
                v-model="portalesExcluidos"
                label="Lista de portales que excluir separados por coma."
                required
                variant="underlined"
                hide-details
                disabled
                :error="error_name"
                class="input-error"
                :class="{error :error_name}"
              />
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field
                label="Número de cursos que buscar."
                type="number"
                v-model="numCursos"
                variant="underlined"
                required
                hide-details
                disabled
                :error="error_name"
                class="input-error"
                :class="{error :error_name}"
              />
            </v-col>
          </div>
        </v-row>
      </v-form>
    <v-row>
      <v-row v-if="loadingRequest" >
        <Loading/>
      </v-row>
      <v-container v-else>
        <v-row v-if="selectedTrend.length > 0">
          <v-col cols="12" >
            <v-btn elevation="4" size="x-large" class="btn button-primary w-100 mb-4 mt-4" @click="onSubmit">Buscar</v-btn>
          </v-col>
        </v-row>
        <v-container v-if="cursosObtenidos.length !== 0">
          <v-row cols="12">
            <v-data-table
              v-model="selectedUrls"
              :headers="columns"
              :items="topHits"
              item-value="name"
              items-per-page="10"
              class="elevation-1"
              return-object
              select-strategy="single"
              show-select
            >
              <template v-slot:item.fecha_actualizacion="{ value }">
                <v-chip :variant="isOutdatedVariant(value)" :color="isOutdated(value)" class="font-weight-bold"> {{ value }} </v-chip>
              </template>
            </v-data-table>
          </v-row>
          <v-row cols="12">
            <v-col cols="3">
              <v-btn size="x-large" elevation="4" class="btn button-primary w-100 " @click="getAnaliticasSemrush">Ver Semrush</v-btn>
            </v-col>
            <v-col cols="3">
              <v-btn size="x-large" elevation="4" class="btn button-primary w-100 " @click="getAnaliticasLighthouse(true)">Ver Lighthouse</v-btn>
            </v-col>
            <v-col cols="3">
              <v-btn size="x-large" elevation="4" class="btn button-primary w-100 " @click="getAnaliticasSF(true)">Ver ScreamingFrog</v-btn>
            </v-col>
            <v-col cols="3">
              <v-btn size="x-large" elevation="4" class="btn button-primary w-100 " @click="createTask"> Crear Tarea </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-container>
    </v-row>
  </v-container>
</template>

<script>
import {onBeforeMount, watch, ref} from "vue";
import {useStore} from "vuex";
import {useRouter} from "vue-router";
import {fetchToken, Endpoints} from "@/utils/auth";
import axios from "axios";
import {showNotification, variableIsDefined} from "@/utils/aux_functions";
import Loading from "@/components/Generic/Loading.vue";

export default {
  name: "RelatedKeywords",
  components: {Loading, variableIsDefined},
  setup() {
    const store = useStore();
    const userGroup = store.getters.getUserGroup.split(',');
    const router = useRouter();
    let idRoles = ref([]);
    let selectedRol = ref([]);
    let idRol = ref(null);
    let selectedIndex = ref();
    watch(selectedRol, async() => {
      idRol.value = idRoles.value.find(item => item.nombre === selectedRol.value).id_rol;
      if (idRol.value === 1){
        selectedIndex.value = "enterprise-search-engine-eduseo-cursos-es-se"
      } else if (idRol.value === 2) {
        selectedIndex.value = "enterprise-search-engine-contenidos-seo-es-se"
      }
      cursosObtenidos.value = [];
      listarTendencias();
    })
    // Esta variable nos define el índice elegido
    /* #region BusquedaAicoreNLP */
    // Aquí definimos parámetros de la llamada de búsqueda a aicorenlp:
    let portalesIncluidos = ref("euroinnova.com");
    let portalesExcluidos = ref("");
    let includeFields = ["url", "keyword_objetivo", "keywords_relacionadas"];
    let mandatoryFields = ["id_url", "id_idioma", "id_portal", "url_portal", "idioma", "fecha_actualizacion", "url_canonica_portal"]
    let excludeFields = [""];
    let numCursos = ref(25);
    // Aquí almacenaramos las keywords únicas insertadas
    let keywordsArray = ref([]);
    // Aquí insertaremos los portales únicos insertados (previo submit) y luego los obtenidos de la petición
    let portalesArray = ref([]);
    /* #endregion BusquedaAicoreNLP */

    /* #region TendenciasNLP */
    let trendsData = ref([]);
    let trendsColumns = [
      {
        'title': 'Keywords',
        'align': 'left',
        'key': 'data'
      },
      {
        'title': 'Score',
        'align': 'center',
        'key': 'score'
      }
    ]
    let selectedTrend = ref([]);
    watch(selectedTrend, async () => {
      cursosObtenidos.value = [];
    });
    /* #endregion TendenciasNLP */

    /* #region AnalizarResultadosBusqueda */
    // Aquí almacenamos el portal / keyword que van a querer analizar (supongo que irán de 1 en 1).
    let selectedPortal = ref("");

    // Aquí guardaremos la información de la keyword seleccionadas
    let topHits = ref([]);
    // Esta variable nos permitirá normalizar las puntuaciones que se enseñan en la tabla
    let maxScore = ref(1.0);
    // Esta es la variable con el nombre de las columnas que vamos ver
    let columns = ref([]);

    let cursosObtenidos = ref([]);
    /* #endregion AnalizarResultadosBusqueda */

    // Flag que nos ayuda saber cuando enseñar que la página está cargando
    let initialRequest = ref(false);
    let loadingRequest = ref(false);

    let error_name = ref(false);

    /* #region VisualizacionBusqueda */
    // Aquí tenemos la información de la tabla de datos
    // Primero eliminaremos algunas columnas de la visualizacion
    let postRequestRemove = new Set(["id_url", "id_portal", "id_idioma", "url_canonica_portal"]);
    // Aquí almacenamos las urls que selecciona el usuario para utilizar
    let selectedUrls = ref([]);
    /* #endregion VisualizacionBusqueda */

    async function listarTendencias(){
      if(!variableIsDefined(idRol.value)){return;}
      initialRequest.value = false;
      let tokenAicore = await getToken();
      await axios({
        method: 'GET',
        url: process.env.VUE_APP_AICORE_URL_API_NLP + "/Trends/listar_tendencias?trabajadas=false&id_rol=" + idRol.value,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + tokenAicore,
          "accept": 'application/json'
        },
      }).then(
      function (response) {
        if (response['data'] && response['data']['result'] == true) {
          trendsData.value = response['data']['data'];
        } else {
          showNotification('error', 'No parecen haber tendencias detectadas (no trabajadas) - Error: ' + response['data']['errors'])
        }
      })
      .catch(function (error) {
        showNotification('error', 'Error al hacer la petición para listar tendencias al servidor. ' + error.message)
      });
      initialRequest.value = true;
    }

    onBeforeMount(async () => {
      initialRequest.value = false;
      let tokenAicore = await getToken();
      await axios({
        method: 'POST',
        url: process.env.VUE_APP_AICORE_URL_API_NLP + "/Trends/listar_roles_grupos",
        data: {
          "include_grupo": userGroup
        },
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + tokenAicore,
          "accept": 'application/json'
        },
      }).then(
      function (response) {
        if (response['data'] && response['data']['result'] == true) {
          let roles = response['data']['data'];
          idRoles.value = [];
          roles.forEach(rol => {
            if (rol['id_rol']!=4){
              idRoles.value.push(rol);
            }
          })
          selectedRol.value = idRoles.value[0]['nombre']
        } else {
          showNotification('error', 'No hemos podido detectar el rol al que perteneces ' + response['data']['errors'])
        }
      })
      .catch(function (error) {
        showNotification('error', 'Error al hacer la petición para obtener su rol al servidor. ' + error.message)
      });

      await listarTendencias();
      initialRequest.value = true;
    })

    function readTableData() {
      // Cogemos los resultados de la primera keyword:
      topHits.value = [];
      columns.value = [];
      cursosObtenidos.value['buckets'].every(item =>{
        if(item['key'] === selectedPortal.value) {
          maxScore.value = item['top_cursos']['hits']['max_score'];
          topHits.value = item['top_cursos']['hits']['hits'];
          columns.value = Object.keys(topHits.value[0]['_source']);
          columns.value.push('score');
          columns.value = columns.value.filter(x => !postRequestRemove.has(x))
          columns.value = columns.value.map(column => new Object({'title': column.split('_').map(item => item.charAt(0).toUpperCase() + item.slice(1)).join(' '), 'align': 'left', 'key': column}));
          let fechaValue = columns.value.splice(columns.value.findIndex(item => item['key'] === "fecha_actualizacion"), 1);
          columns.value.splice(columns.value.length - 1, 0, fechaValue[0]);
          let values = []
          topHits.value.forEach(hit => {
            delete hit['_ignored'];
            hit['_source']['score'] = hit['_score'] / maxScore.value + 0.0001;
            hit['_source']['score'] = hit['_source']['score'].toFixed(3);
            let fecha = new Date(hit['_source']['fecha_actualizacion']);
            hit['_source']['fecha_actualizacion'] = fecha.toLocaleDateString('es-ES', {year:'numeric', month: '2-digit', day: '2-digit'});
            values.push(hit['_source']);
          })
          topHits.value = values;
        }
      });
    }

    function getAnaliticasSemrush() {
      if (selectedUrls.value.length == 0){ return; }
      let domain = selectedUrls.value[0]['url_canonica_portal'];
      let urls = [];
      selectedUrls.value.forEach(url => {
        urls.push(url['url'].toLowerCase());
      });
      const routeData = router.resolve({
        path: "/dashboards/396",
        query: { Path: urls.join(','), Domain: domain}
      });
      window.open(routeData.href, '_blank');
    }

    async function getAnaliticas(method, endpoint, params, data, domain, dashboard, redirect){
      let wo = null;
      if (redirect){
        wo = window.open('about:blank', '_blank');
      }
      let shouldRedirect = false;
      let tokenAicore = await getToken(Endpoints.ANALYTICS.name);
      if (tokenAicore != null){
        await axios({
          method,
          url: "https://eduseoanalytics.educaedtech.com" + endpoint,
          params,
          data,
          headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + tokenAicore,
            "accept": 'application/json'
          },
        })
        .then(
          function (response) {
            if (response['data'] && response['data']['result'] == true) {
              shouldRedirect = true;
            } else {
              shouldRedirect = false;
            }
          })
          .catch(function (error) {
            showNotification('error', 'Error al hacer la petición al servidor. ' + error.message)
          });
      }
      if (shouldRedirect && redirect && wo){
        let urls = [];
        selectedUrls.value.forEach(url => {
          urls.push(url['url']);
        });
        const routeData = router.resolve({
          path: dashboard,
          query: { Path: urls.join(','), Domain: domain}
        });
        wo.location.href = routeData.href;
      }else{
        if (wo){
          wo.close();
        }
      }
      loadingRequest.value = false;

    }

    async function getAnaliticasLighthouse(redirect) {
      if (selectedUrls.value.length == 0){ return; }
      loadingRequest.value = true;
      let domain = selectedUrls.value[0]['url_canonica_portal'];
      let urls = [];
      selectedUrls.value.forEach(url => {
        urls.push('https://' + domain + '/' + url['url']);
      });
      let params = {
        'dispositivo': 'escritorio',
        'return_html': false,
      };
      let endpoint = "/api/Lighthouse/get_lighthouse_reports?list_urls=" + urls.join('&list_urls=');
      await getAnaliticas('GET', endpoint, params, {}, domain, "/dashboards/398", redirect);
    }


    async function getAnaliticasSF(redirect) {
      if (selectedUrls.value.length == 0){ return; }
      loadingRequest.value = true;
      let domain = selectedUrls.value[0]['url_canonica_portal'];
      let urls = [];
      selectedUrls.value.forEach(url => {
        urls.push({
          url: 'https://' + domain + '/' + url['url'],
          id_url: url['id_url'],
          id_portal: parseInt(url['id_portal']),
          id_idioma: parseInt(url['id_idioma']),
        });
      });
      let params= {
        'internal_mode': true,
        'redirects_mode': true,
        'hyperlinks_mode': true,
        'hreflang_mode': true,
        'return_results': false,
      };
      let data = {
        'list_urls': urls
      }
      let endpoint = "/api/ScreamingFrog/analiticas_screamingfrog"
      await getAnaliticas('POST', endpoint, params, data, domain, "/dashboards/401", redirect);
    }


    async function getToken(endpoint = Endpoints.AICORE.name) {
      // Esta función nos permite obtener el token de autentificación de AICORE
      let tokenAicore = null;
      try {
        tokenAicore = await fetchToken(endpoint);
        tokenAicore = tokenAicore['access_token'];
      } catch (e){
        showNotification('error', 'Error al autentificarse con el servidor de aicore');
        return null;
      }
      return tokenAicore;
    }


    function isOutdated(fecha) {
      // Normalize the date string (localeDateString('es-ES'....))
      fecha = fecha.split('/').reverse().join('-');
      let today = new Date();
      let reference = new Date(fecha);
      let difference = (today.getFullYear() - reference.getFullYear()) * 12 + (today.getMonth() - reference.getMonth());
      if ( difference >= 2 ){
        return 'red';
      } else if (difference == 1) {
        return 'orange'
      } else {
        return '';
      }
    }

    function isOutdatedVariant(fecha){
      // Normalize the date string (localeDateString('es-ES'....))
      fecha = fecha.split('/').reverse().join('-');
      let today = new Date();
      let reference = new Date(fecha);
      let difference = (today.getFullYear() - reference.getFullYear()) * 12 + (today.getMonth() - reference.getMonth());
      if ( difference >= 1 ){
        return 'tonal';
      } else {
        return 'text';
      }

    }

    async function insertPendingAnalytics(){
      if (selectedTrend.value.length == 0){ return; }
      loadingRequest.value = true;
      let endpoint = "/Trends/insertar_analitica_pendiente"
      let tokenAicore = await getToken();
      let shouldContinue = false;
      if (tokenAicore != null){
        await axios({
          method: 'GET',
          url: process.env.VUE_APP_AICORE_URL_API_NLP + endpoint,
          params: {
            'is_trend': false,
            'id_tendencia': selectedTrend.value[0]['id_tendencia'],
          },
          headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + tokenAicore,
            "accept": 'application/json'
          },
        })
        .then(
          function (response) {
            if (response['data'] && response['data']['result'] == true) {
              shouldContinue = true;
            } else {
              shouldContinue = false;
            }
          })
          .catch(function (error) {
            showNotification('error', 'Error al hacer la petición al servidor. ' + error.message)
          });
      }
      loadingRequest.value = false;
      return shouldContinue;
    }

    async function createTask(){
      initialRequest.value = false;
      let shouldContinue = await insertPendingAnalytics();
      if (!shouldContinue){
        showNotification('error', 'Error al crear la solicitud de tendencia; No fue posible analizar las urls.');
        return false;
      }
      let tokenAicore = await getToken();
      let selectedUrlData = {}
      let score = 0;
      if(selectedUrls.value.length > 0){
        selectedUrlData = {
          'id_url': selectedUrls.value[0]['id_url'],
          'id_portal': selectedUrls.value[0]['id_portal'],
          'id_idioma': selectedUrls.value[0]['id_idioma'],
        };
        score = selectedUrls.value[0]['score'];
      }
      if (tokenAicore != null){
        await axios({
          method: 'POST',
          url: process.env.VUE_APP_AICORE_URL_API_NLP + "/Trends/crear_tarea",
          data: {
            'id_tendencia': selectedTrend.value[0]['id_tendencia'],
            'id_rol': idRol.value,
            'id_estado': 1,
            'prioridad': 0.7 * selectedTrend.value[0]['score'] + 0.3 * score,
            ... selectedUrlData,
          },
          headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + tokenAicore,
            "accept": 'application/json'
          },
        })
        .then(
          function (response) {
            if (response['data'] && response['data']['result'] == true) {
              listarTendencias();
            } else {
              showNotification('error', 'Error al hacer la petición al servidor. ' + response['data']['errors'])
            }
          })
          .catch(function (error) {
            showNotification('error', 'Error al hacer la petición al servidor. ' + error.message)
          });
      }
      cursosObtenidos.value = [];
      selectedTrend.value = [];
      selectedUrls.value = [];
    }

    async function onSubmit() {
      /*
         Esta función se encargará de hacer la petición a aicorenlp con todos los parámetros adecuados para obtener los resultados.
         También se ocupará de gestionar dicha respuesta (Error / Ok)
       */
      loadingRequest.value = true;
      let jsonBody = {};
      try {
        keywordsArray.value = [];
        JSON.parse(selectedTrend.value[0]['data']).forEach(item => keywordsArray.value.push(item['keyword']));
        portalesArray.value = Array.from(new Set(portalesIncluidos.value.split(',')));
        jsonBody = {
          'index': selectedIndex.value,
          'keywords': keywordsArray.value,
          'independientes': false,
          'incluir_url_portales': portalesArray.value,
          'excluir_url_portales': portalesExcluidos.value != "" ? portalesExcluidos.value.split(',') : null,
            'incluir_campos': [...includeFields, ...mandatoryFields],
            'excluir_campos': excludeFields,
            'num_cursos': numCursos.value,
        };
      } catch (error){
        showNotification('error', 'Error al leer los argumentos, verifique los campos rellenos.');
        return false;
      }
      let tokenAicore = await getToken();
      if (tokenAicore != null){
        axios.post(
          process.env.VUE_APP_AICORE_URL_API_NLP + "/SearchEngineProduction/search/search_courses_on_keywords",
          jsonBody,
          {
            headers: {
              'Content-Type': 'application/json',
              'Authorization': 'Bearer ' + tokenAicore,
              "accept": 'application/json'
            },
          }
        )
        .then(
          function (response) {
            if (response['data'] && response['data']['result'] == true) {
              try {
                cursosObtenidos.value = response['data']['data'];
                if (cursosObtenidos.value.length !== 0){
                  // We return a data array in that ws
                  cursosObtenidos.value = cursosObtenidos.value[0];
                  portalesArray.value = [];
                  cursosObtenidos.value['portales']['buckets'].forEach(item => {
                    portalesArray.value.push(item['key'])
                  });
                  // Vamos a suponer que empezarán secuencialmente
                  selectedPortal.value = portalesArray.value[0];
                  // Transformamos nuestra respuesta en un diccionario donde las llaves son las keywords
                  cursosObtenidos.value = cursosObtenidos.value['portales'];
                  readTableData();
                }
              } catch(error) {
                showNotification('error', 'Error al procesar la respuesta del servidor. ' + response['data']['errors'])
              }
            } else {
              cursosObtenidos.value = [];
            }
          })
          .catch(function (error) {
            showNotification('error', 'Error al hacer la petición al servidor. ' + error.message)
          });
      }
      loadingRequest.value = false;
    }

    function getFormattedValues(jsonString) {
      return JSON.parse(jsonString).map(obj => obj.keyword).join(', ');
    }

    return {
      idRoles,
      selectedRol,
      idRol,
      portalesIncluidos,
      portalesExcluidos,
      includeFields,
      mandatoryFields,
      excludeFields,
      numCursos,

      keywordsArray,
      portalesArray,
      selectedPortal,

      readTableData,
      postRequestRemove,
      selectedUrls,
      isOutdated,
      isOutdatedVariant,

      loadingRequest,
      initialRequest,
      getAnaliticas,
      getAnaliticasSemrush,
      getAnaliticasLighthouse,
      getAnaliticasSF,
      getFormattedValues,
      createTask,

      trendsData,
      trendsColumns,
      selectedTrend,

      userGroup,

      maxScore,
      topHits,
      columns,

      cursosObtenidos,

      onSubmit,
      error_name,
    }
  }
}
</script>
