<template>
    <v-container>
        <div v-if="loading_general">
            <Loading/>
        </div>
        <template v-else>
        <v-row>
            <v-col cols="12" class="section-title">
                <span class="main-title-page">Generación de guiones para cursos</span>
            </v-col>
        </v-row>

            <v-row>
                <v-col cols="12" sm="6" md="4">
                    <span class="input-title">Código Innotutor *</span>
                    <v-text-field
                        v-model="form_data.code_innot"
                        class="input-error text-field-secondary"
                        bg-color="white"
                        color="transparent"
                        @keyup.enter="getIntroVideo"
                        :error="form_error.code_innot"
                        :menu-props="{ width: '300px'}"
                        hide-details
                    >
                    </v-text-field>
                    <span v-if="form_error.code_innot" class="input-error msg-error">
                      {{ form_error.code_innot }}
                    </span>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                    <span class="input-title">Empresa *</span>
                    <v-select
                        v-model="form_data.company"
                        :items="all_companies"
                        item-title="nombre_empresa"
                        item-value="id_empresa"
                        class="input-error select-secondary"
                        bg-color="white"
                        no-data-text="No hay datos disponibles"
                        :error="form_error.company"
                        :menu-props="{ width: '400px'}"
                        hide-details
                    >
                    </v-select>
                    <span v-if="form_error.company" class="input-error msg-error">
                      {{ form_error.company }}
                    </span>
                </v-col>
                <v-col cols="12" sm="6" md="2">
                    <span class="input-title">Idioma *</span>
                    <v-select
                        v-model="form_data.language"
                        :items="available_languages"
                        item-title="nombre"
                        item-value="iso_6391"
                        class="input-error select-secondary"
                        bg-color="white"
                        no-data-text="No hay datos disponibles"
                        :menu-props="{ width: '300px'}"
                        hide-details
                    >
                    </v-select>
                </v-col>
                <v-col cols="12" sm="6" md="2">
                    <span class="input-title">Modelo *</span>
                    <v-select
                        v-model="form_data.model"
                        :items="items_model"
                        item-title="name"
                        item-value="id"
                        class="input-error select-secondary"
                        bg-color="white"
                        no-data-text="No hay datos disponibles"
                        :menu-props="{ width: '300px'}"
                        hide-details
                    >
                    </v-select>
                </v-col>
                <v-col cols="12" class="text-end">
                    <v-btn class="btn button-primary mb-sm-3"
                           :loading="loading_btn"
                           hide-details
                           @click="getIntroVideo">
                        GENERAR GUION
                    </v-btn>
                </v-col>
            </v-row>

            <template v-if="display_sections">
                <v-row class="mb-4">
                    <v-col>
                        <span class="input-title mb-3">Parte 1. Generación de intro de guión: </span>
                        <v-progress-circular v-if="loading_intro"
                                             indeterminate
                                             color="primary"
                        ></v-progress-circular>
                        <span id="intro_generated"></span>

                    </v-col>
                </v-row>
                <v-row class="mb-4">
                    <v-col>
                        <span class="input-title mb-3">Parte 2. Generación de guión de producto: </span>
                        <v-progress-circular v-if="loading_guion"
                                             indeterminate
                                             color="primary"
                        ></v-progress-circular>
                        <span id="guion_generated"></span>
                    </v-col>
                </v-row>
            </template>
        </template>
    </v-container>
</template>

<script>
import {onBeforeMount, ref} from "vue";
import {getGuionVideoWS, getIntroVideoWS, getListCompaniesCoursesInnotutorWS} from "@/utils/product_video";
import * as Yup from "yup";
import {manageWSResponses, showNotification, variableIsDefined} from "@/utils/aux_functions";
import {hasPermission} from "@/utils/auth";
import {useRoute} from "vue-router";
import Loading from "@/components/Generic/Loading.vue";
import {getBaseLanguagesWS} from "@/utils/html_traduction";
import {getAllLanguagesWS} from "@/utils/languages";

export default {
    name: "GenerationOfCourseScriptsView",
    components: {Loading},
    methods: {variableIsDefined},

    setup() {
        let loading_general = ref(false)
        let form_data = ref({
            language: 'es',
            model: 'gpt-4o'
        })
        let form_error = ref({})
        let items_language = ref([
            {
                id: 'es',
                name: 'Español'
            }
        ])
        let items_model = ref([
            {
                id: 'gpt-4o',
                name: 'GPT-4'
            },
            {
                id: 'gpt-4o-mini',
                name: 'GPT-4 mini'
            },
        ])

        let loading_btn = ref(false)
        let loading_intro = ref(false)
        let loading_guion = ref(false)
        let display_sections = ref(false)
        let text_generated_ws = ref('')
        let guion_generated_ws = ref('')
        let all_companies = ref([])
        const route = useRoute();

        let available_languages = ref([])

        onBeforeMount(async () => {
            loading_general.value = true
            await hasPermission(route);
            await getListCompaniesCoursesInnotutor()
            await loadAvailableLanguages()
            loading_general.value = false
        })

        /*Carga los lenguajes base de aiseo*/
        const loadAvailableLanguages = async () => {
            const response = await manageWSResponses(getAllLanguagesWS, [{}])
            if (response){
                Object.entries(response['data']).map(
                    language => {
                        if (language[1].activo){
                            available_languages.value.push(language[1])
                        }
                    }

                )
            }
        }

        async function getListCompaniesCoursesInnotutor() {
            let response = await getListCompaniesCoursesInnotutorWS()
            if (response['result']) {
                all_companies.value = response['data']
            } else {
                showNotification('error', response['errors'])
            }
        }

        async function validateForm() {
            form_error.value = {}
            const schemaForm = Yup.object().shape({
                company: Yup.string().required('El campo es obligatorio'),
                code_innot: Yup.string()
                    .matches(/^[a-zA-Z0-9-_]+$/, 'El campo solo puede contener letras, números y guiones')
                    .required('El campo es obligatorio'),
            });
            try {
                await schemaForm.validate(form_data.value, {abortEarly: false})
                return true
            } catch (error) {
                error.inner.forEach((error) => {
                    form_error.value[error.path] = error.message;
                })
                return false
            }
        }

        async function getIntroVideo() {
            $('#intro_generated').html('')
            $('#guion_generated').html('')
            loading_btn.value = true;
            display_sections.value = false;

            if (await validateForm()) {
                display_sections.value = true;
                loading_intro.value = true
                loading_guion.value = true

                let response = await getGuionVideoWS(form_data.value)
                if (response['result']) {
                    $('#intro_generated').html(response['data'].replace(/\n/g, "<br>"))
                } else {
                    showNotification('error', response['errors'])
                }
                loading_intro.value = false

                response = await getIntroVideoWS(form_data.value)
                if (response['result']) {
                    $('#guion_generated').html(response['data'].replace(/\n/g, "<br>"))
                } else {
                    showNotification('error', response['errors'])
                }
                loading_guion.value = false



            }
            loading_btn.value = false
        }

        return {
            getIntroVideo,

            loading_general,
            form_data,
            form_error,
            all_companies,
            items_language,
            items_model,
            text_generated_ws,
            guion_generated_ws,
            loading_btn,
            loading_intro,
            loading_guion,
            display_sections,
            available_languages,
        }
    }
}
</script>


<style scoped lang="scss">
</style>